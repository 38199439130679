@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900&family=Poppins:wght@300;400;500;600;700&display=swap');
* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #072537;
}

body::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
body::-webkit-scrollbar-thumb {
  background-color: #8ca3b8;
}
body {
  background-color: #072537;
}
.App {
  overflow: hidden;
  position: relative;
}
p,
li,
span,
a {
  font-family: 'DM Sans', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.5px;
  color: #8ca3b8;
}
h1,
h2,
h3,
h4,
h5 {
  color: #f5f5f5;
  font-family: 'Poppins', sans-serif;
}
.flex .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.container {
  width: 1200px;
  display: table;
  margin: auto;
}
.container-fluid {
  padding: 0 2%;
}
.main-head.flex .flex {
  display: flex;
  gap: 60px;
}

.main-head.flex .flex a.nav-menu.active {
  color: #0099f4;
}
.main-head.flex .flex a.nav-menu {
  color: #f5f5f5;
}
.main-head.flex .flex:last-child {
  gap: 30px;
}
.rakec {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lerak {
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: 16px;
  font-weight: 400;
  margin-left: 8px;
  color: #f5f5f5;
}
.lerak p {
  color: #f5f5f5;
  margin: 0;
  font-size: 14px;
}
.lerak span {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.lerak span span {
  margin: 0 0 0 5px;
}
.lerak span.green {
  color: #49ba36;
}
.lerak span.red {
  color: red;
}
.lerak span.red img {
  transform: rotate(180deg);
  filter: revert;
  filter: brightness(0) saturate(100%) invert(32%) sepia(53%) saturate(2850%) hue-rotate(336deg)
    brightness(93%) contrast(97%);
}
.lerak span img {
  margin-left: 6px;
}

header {
  padding: 1% 0;
}

footer {
  padding: 3% 0 2%;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 0 0 16%;
  margin-right: 2%;
}
footer ul li a:hover {
  color: #f5f5f5;
}

footer ul a {
  transition: all 0.5s ease;
}
footer p {
  margin: 18px 0 5px;
}
footer ul a img {
  position: relative;
  transform: scale(1);
  transition: all 0.5s ease;
}

footer ul a:hover img {
  transform: scale(1.1);
}
.full-column {
  width: 40%;
  flex: 0 0 40%;
}

.column:last-child {
  margin: 0;
}

.full-column img {
  width: 45%;
}

.full-column .btn {
  width: auto;
  max-width: max-content;
  margin-top: 20px;
  padding: 12px 32px;
}

h5 {
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px; /* 17.5px */
  color: #8ca3b8;
  margin-bottom: 25px;
}

.row.copyrght {
  width: 100%;
  text-align: center;
  justify-content: center;
  padding-top: 20px;
  border-top: 1px solid #293c53;
  margin-top: 24px;
}
footer .flex .row {
  align-items: flex-start;
}
.column ul li {
  margin-bottom: 8px;
}
ul.socl a {
  margin-right: 10px;
}
ul.socl a img {
  width: 30px;
}

ul.socl a:last-child {
  margin: 0;
}
.col2 {
  width: 49%;
  flex: 0 0 49%;
}

h2 {
  background: linear-gradient(90deg, #e6f71b 0%, #23ffd3 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 96px;
  font-style: italic;
  font-weight: 700;
  line-height: 110px; /* 114.583% */
  text-transform: uppercase;
}

h3 {
  font-size: 40px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  margin: 22px 0;
}

p {
  margin-bottom: 20px;
}

.btn:after {
  content: '';
  position: absolute;
  background: #0099f4;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  transform: skewX(345deg);
  z-index: -25;
}

.btn {
  position: relative;
  z-index: 99;
}

.btn:before {
  content: '';
  position: absolute;
  background: #e6f71b;
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  transform: skewX(345deg);
  z-index: -1;
  transition: all 0.5s ease;
}

.btn:hover:before {
  width: 100%;
}
.col1 {
  width: 39%;
  flex: 0 0 39%;
}

.col3 {
  width: 59%;
  flex: 0 0 59%;
  align-self: normal;
}

.explore {
  padding: 5% 0;
  position: relative;
}

.explore .flex .row {
  gap: 2%;
}
.App section {
  padding: 6% 0;
  position: relative;
}
.mgbtm {
  margin-bottom: 20px;
}
.privacy-content p a {
  background: linear-gradient(90deg, #e6f71b 0%, #23ffd3 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media all and (max-width: 767px) and (min-width: 320px) {
  .player .vp-video-wrapper video {
    object-fit: scale-down;
  }

  .js-player-fullscreen {
    width: 100% !important;
    max-width: 100% !important;
  }

  .explore .col3 {
    height: 230px;
  }
  .flex.nav-menu {
    position: fixed;
    width: 100%;
    height: calc(100vh - 53px);
    background: #04141e;
    bottom: 0;
    z-index: 9999;
    align-items: center;
    flex-direction: column;
    left: 0;
    padding: 30px 20px;
    justify-content: flex-start !important;
    left: -100%;
    transition: all 0.5s ease;
  }

  header {
    padding: 4% 0;
  }

  .main-head.flex .flex {
    align-items: self-start;
    width: 100%;
    justify-content: space-between;
  }

  .flex.nav-menu a {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    font-style: italic;
    font-family: 'Poppins';
    text-align: center;
    width: 100%;
  }

  .main-head.flex .flex.nav-menu a.nav-menu {
    display: block;
  }

  .rakec {
    width: 100%;
    justify-content: flex-start;
    padding: 20px 0 0 0;
    border-top: 1px solid #072537;
  }

  .flex.nav-menu.active {
    left: 0;
  }
  .main-head.flex .flex a.nav-menu {
    display: none;
  }
  .herosect .flex > .row > img {
    width: 100%;
  }

  .herosect .flex .row h1 {
    font-size: 16px;
  }

  .App .herosect .btn {
    font-size: 18px;
  }

  .App .herosect p {
    font-size: 14px;
  }

  .arpost img.fullimg {
    width: 10px;
  }

  .App .herosect {
    background-position: center;
  }
  .container {
    width: 100%;
    padding: 0 5%;
  }
  .map {
    padding: 30% 0;
  }
  .col1,
  .col3,
  .col2,
  .full-column,
  .column {
    width: 100%;
    flex: 0 0 100%;
  }

  .herosect .row > img {
    width: 100%;
  }

  h1 {
    font-size: 40px;
  }

  .herosect .flex .row {
    padding: 0;
  }

  .herosect .btn {
    width: 100%;
  }

  a.logosec img {
    width: 80%;
  }

  .container-fluid {
    padding: 0 4%;
  }

  h2 {
    font-size: 64px;
    line-height: 80px;
  }

  h3 {
    font-size: 38px;
    line-height: 50px;
    margin: 10px 0 20px;
  }

  img.fullimg {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .explore .col1 {
    margin-bottom: 40px;
  }

  .explore:after,
  .buysell:after,
  .buysell:before,
  .collect:before {
    bottom: 0;
    left: 0;
    right: 0;
    top: initial;
    z-index: -1;
  }

  .discover,
  .RakeCoins {
    padding: 35% 0 !important;
  }

  .App section {
    padding: 12% 0;
  }

  .burn:before {
    top: 0;
    bottom: initial;
  }

  .burn h2.mgbtm {
    margin-top: 35px;
  }

  .column {
    margin: 19px 0;
  }

  .full-column .btn {
    width: 100%;
    max-width: 100%;
    margin-top: 25px;
  }

  .full-column img {
    width: 65%;
  }
}
@media all and (max-width: 1023px) and (min-width: 768px) {
  .container.flex {
  }

  .container {
    width: 700px;
  }

  h2 {
    font-size: 60px;
    line-height: 69px;
  }

  img.fullimg {
    width: 100%;
  }

  h3 {
    font-size: 34px;
    line-height: 40px;
  }

  .col1 {
    width: 100%;
    flex: 0 0 100%;
  }

  .col3 {
    width: 100%;
    flex: 0 0 100%;
  }

  .explore .col3 {
    margin-top: 39px;
  }

  body .full-column {
    width: 31%;
    flex: 0 0 26%;
  }

  .column {
    flex: 0 0 17%;
    width: 17%;
    margin-right: 6px;
  }

  .full-column img {
    width: 86%;
  }

  .full-column .btn {
    margin-top: 20px;
  }

  ul.socl a {
    margin-right: 9px;
  }

  ul.socl a img {
    width: 23px;
  }
}
@media all and (max-width: 1200px) and (min-width: 1024px) {
  .container {
    width: 940px;
  }

  img.fullimg {
    width: 100%;
  }

  h2 {
    font-size: 80px;
    line-height: 95px;
  }

  footer ul a img {
    width: 28px;
  }

  ul.socl a {
    margin-right: 10px;
  }

  .full-column img {
    width: 55%;
  }

  .full-column .btn {
    margin-top: 20px;
  }
}
