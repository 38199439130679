.world-map {
  text-align: center;
  background: url(../../Assets/images/world-map-bg.png), #04141e;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 0 10% 0;
}
.world-map-hero {
  padding: 8% 0;
}
.map {
  padding: 12% 0;
  background: url(../../Assets/images/Discoverbg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.world-map button.btn {
  margin-top: 4%;
}
