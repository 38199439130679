.lerak p b {
  margin-right: 4px;
}

.row.text-center {
  text-align: center;
}

.herosect .flex .row {
  justify-content: center;
  flex-direction: column;
  padding: 0 8%;
}
.herosect:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  background: rgb(7 37 55 / 30%);
  z-index: 0;
}

.herosect .flex {
  position: relative;
  z-index: 9;
}
.App .herosect .btn {
  font-size: 1.3vw;
}

.App .herosect p {
  font-size: 1.1vw;
  margin: 0;
}

.arpost img.fullimg {
  width: 0.9vw;
}
.herosect .btn {
  margin: 2vw 0;
}
.herosect .flex .row h1 {
  font-family: Poppins;
  font-size: 1.678vw;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
}

.btn {
  font-family: Poppins;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  color: #fff;
  padding: 12px 24px;
  background: transparent;
  border: 0;
  cursor: pointer;
}
.App .herosect {
  height: 90vh;
  box-sizing: border-box;
  padding: 2vw 0 1vw;
}
.herosect {
  padding: 8% 0 2%;
  background: url(../../Assets/images/herobg.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-attachment: fixed;
}
a.btm {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}


.arpost p {
  margin: 0;
}

.herosect .flex > .row > img {
  width: 40vw;
}
.herosect .flex {
  position: relative;
  z-index: 9;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.herosect .btn {
  margin: 24px 0;
}

ul.explreul li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 22px;
}

ul.explreul li img {
  margin-right: 12px;
}

.explore:after,
.buysell:after,
.buysell:before,
.burn:before,
.collect:before {
  border-radius: 500px;
  opacity: 0.4;
  background: #e6f71b;
  filter: blur(200px);
  width: 500px;
  height: 500px;
  position: absolute;
  content: '';
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  margin: auto;
}
.buysell:after,
.burn:before {
  right: initial;
  left: 0;
}
.buysell:after,
.buysell:before,
.collect:before {
  background: #23ffd3;
}

.discover {
  background: url(../../Assets/images/Discoverbg.png);
  background-position: center;
  background-size: 100% 100%;
  padding: 8% 0 !important;
  background-repeat: no-repeat;
}

.gap .row {
  gap: 2%;
}
.blacktext h2,
.blacktext p {
  color: #343748;
  -webkit-text-fill-color: #343748;
}

.buysell h2 {
  width: 100%;
}
.RakeCoins {
  background: url(../../Assets/images/Coinsbg.png);
  background-position: center;
  background-size: 100% 100%;
  padding: 8% 0 !important;
  background-repeat: no-repeat;
}
.collect .fullimg {
  width: 100%;
  margin-top: 60px;
}

.App .collect {
  padding-bottom: 0;
}
.discover .row {
  flex-direction: row-reverse;
}
